<template>
    <div class="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true">
        <div class="px-7 py-5">
            <div class="fs-5 text-dark fw-bolder">{{ $t('filters.title') }}</div>
        </div>

        <div class="separator border-gray-200"></div>

        <div class="px-7 py-5 overflow-auto max-h-300px">
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.ecommerce.order.shipping.orderNo') }}:</label>
                <div>
                    <el-input v-model="filterData.id" oninput="value=value.replace(/[^0-9.]/g,'')"/>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.ecommerce.order.shipping.user') }}:</label>
                <div>
                    <el-select
                        class="w-100"
                        v-model="filterData.user_id"
                        :filterable="user.filterable"
                        clearable
                        remote
                        :remote-method="remoteSearchUser"
                        :loading="user.loading"
                        :placeholder="$t('filters.ecommerce.invoice.userSearch')">
                        <el-option v-for="(user, userIndex) in user.list" :key="userIndex" :value="user.id" :label="user.full_name + ' (' + user.email +')'"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.ecommerce.order.shipping.userEmail') }}:</label>
                <div>
                    <el-input v-model="filterData.user_email"/>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.ecommerce.order.shipping.shipping') }}:</label>
                <div>
                    <el-select v-model="filterData.shipping_methods" class="w-100" :placeholder="$t('common.chooseSelect')" clearable multiple filterable>
                        <el-option v-for="(shipping, shippingIndex) in shippingMethods" :key="shippingIndex" :value="shipping.id" :label="shipping.name"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="">
                <label class="form-label fw-bold">{{ $t('filters.ecommerce.order.shipping.shippingStatus') }}:</label>
                <div>
                    <el-select v-model="filterData.shipping_statuses" class="w-100" :placeholder="$t('common.chooseSelect')" clearable multiple filterable>
                        <el-option v-for="(state, stateIndex) in shippingStatus" :key="stateIndex" :value="(stateIndex + 1)" :label="$t('pages.ecommerce.order.shipping.shippingStatus.' + state)"></el-option>
                    </el-select>
                </div>
            </div>
        </div>
        <div class="separator border-gray-200"></div>
        <div class="px-7 py-5">
            <div class="d-flex justify-content-end">
                <button type="reset" v-on:click="onReset" class="btn btn-sm btn-white btn-active-light-primary me-2" data-kt-menu-dismiss="true">{{ $t('btn.reset') }}</button>
                <button type="submit" v-on:click="onFilter" class="btn btn-sm btn-primary" data-kt-menu-dismiss="true">{{ $t('btn.apply') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "index",
    data(){
        return {
            filterData: {
                shipping_statuses: [1,3,4,5]
            },
            user: {
                filterable: true,
                loading: false,
                list: []
            },
            shippingStatus: ['pending', 'manuelSend', 'tobeProcessed', 'processed', 'shipped', 'delivered', 'error']
        }
    },
    computed: {
        shippingMethods() {
            return this.$store.state.ecommerce.shippingMethod.table.data;
        },
    },
    mounted() {
        this.$store.dispatch('ecommerce/shippingMethod/get', {
            page: { pageSize: 9999 }
        });
    },
    methods: {
        onReset(){
            this.filterData = {};
            this.onFilter();
        },
        onFilter(){
            this.$emit('onFilter', this.filterData);
        },
        remoteSearchUser(query) {
            if (query) {
                this.user.loading = true;
                this.axios.get(this.endpoints['user'], {
                    params: {
                        magic: query
                    }
                }).then(response => {
                    let data = response.data.data;
                    this.user.list = data;
                }).finally(() => {
                    this.user.loading = false;
                });
            } else {
                this.user.list = [];
            }
        },
    }
}
</script>

<style>

</style>