<template>
    <div class="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true">
        <div class="px-7 py-5">
            <div class="fs-5 text-dark fw-bolder">{{ $t('filters.title') }}</div>
        </div>

        <div class="separator border-gray-200"></div>

        <div class="px-7 py-5 overflow-auto max-h-300px">
            <div class="mb-10" v-if="!examID">
                <label class="form-label fw-bold">{{ $t('filters.module.exam.result.examID') }}:</label>
                <div>
                    <el-input v-model="filterData.exam_id" type="text"/>
                </div>
            </div>
            <div v-if="!sessionID" :class="!userID && 'mb-10'">
                <label class="form-label fw-bold">{{ $t('filters.module.exam.result.sessionID') }}:</label>
                <div>
                    <el-input v-model="filterData.session_id" type="text"/>
                </div>
            </div>
            <template v-if="!userID">
                <div class="mb-10">
                    <label class="form-label fw-bold">{{ $t('filters.module.exam.result.userID') }}:</label>
                    <div>
                        <el-input v-model="filterData.user_id" type="text"/>
                    </div>
                </div>
                <div class="">
                    <label class="form-label fw-bold">{{ $t('filters.module.exam.result.userEmail') }}:</label>
                    <div>
                        <el-input v-model="filterData.user_email" type="text"/>
                    </div>
                </div>
            </template>
        </div>

        <div class="separator border-gray-200"></div>

        <div class="px-7 py-5">
            <div class="d-flex justify-content-end">
                <button type="reset" v-on:click="onReset" class="btn btn-sm btn-white btn-active-light-primary me-2" data-kt-menu-dismiss="true">{{ $t('btn.reset') }}</button>
                <button type="submit" v-on:click="onFilter" class="btn btn-sm btn-primary" data-kt-menu-dismiss="true">{{ $t('btn.apply') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import ElSelectTree from "@/components/ElSelectTree.vue";

export default {
    name: "index",
    components: {
        ElSelectTree
    },
    data(){
        return {
            filterData: {}
        }
    },
    computed: {
        sessionID(){
            return this.$route.query.sessionID;
        },
        examID(){
            return this.$route.query.examID;
        },
        userID(){
            return this.$route.query.userID;
        }
    },
    mounted(){
        if(this.sessionID){
            this.filterData.session_id = this.sessionID;
        }
        if(this.examID){
            this.filterData.exam_id = this.examID;
        }
        if(this.examID){
            this.filterData.exam_id = this.userID;
        }
    },
    methods: {
        onReset(){
            this.filterData = {};
            this.onFilter();
        },
        onFilter(){
            this.$emit('onFilter', this.filterData);
        }
    }
}
</script>

<style>

</style>