<template>
    <div class="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true">
        <div class="px-7 py-5">
            <div class="fs-5 text-dark fw-bolder">{{ $t('filters.title') }}</div>
        </div>

        <div class="separator border-gray-200"></div>

        <div class="px-7 py-5 overflow-auto max-h-300px">

            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.ecommerce.order.changeUsageMulti.admin') }}:</label>
                <div>
                    <el-select v-model="filterData.admin_id" class="w-100" :placeholder="$t('common.chooseSelect')" clearable>
                        <el-option v-for="(admin, adminIndex) in admins" :key="adminIndex" :value="admin.id" :label="admin.firstname + ' ' + admin.lastname"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="">
                <label class="form-label fw-bold">{{ $t('filters.ecommerce.order.changeUsageMulti.state') }}:</label>
                <div>
                    <el-select v-model="filterData.status_id" class="w-100" :placeholder="$t('common.chooseSelect')" clearable>
                        <el-option v-for="(state, stateIndex) in status" :key="stateIndex" :value="stateIndex" :label="$t('pages.ecommerce.orderManagement.changeUsageMulti.status.' + state)"></el-option>
                    </el-select>
                </div>
            </div>
        </div>
        <div class="separator border-gray-200"></div>
        <div class="px-7 py-5">
            <div class="d-flex justify-content-end">
                <button type="reset" v-on:click="onReset" class="btn btn-sm btn-white btn-active-light-primary me-2" data-kt-menu-dismiss="true">{{ $t('btn.reset') }}</button>
                <button type="submit" v-on:click="onFilter" class="btn btn-sm btn-primary" data-kt-menu-dismiss="true">{{ $t('btn.apply') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "index",
    data(){
        return {
            filterData: {},
            status: ['pending', 'processing', 'completed', 'cancelled', 'error']
        }
    },
    computed:{
        admins(){
            return this.$store.state.system.admin.table.data;
        }
    },
    mounted(){
        this.$store.dispatch('system/admin/get', {
            page: { pageSize: 9999 }
        });
    },
    methods: {
        onReset(){
            this.filterData = {};
            this.onFilter();
        },
        onFilter(){
            this.$emit('onFilter', this.filterData);
        }
    }
}
</script>

<style>

</style>