<template>
    <div class="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true">
        <div class="px-7 py-5">
            <div class="fs-5 text-dark fw-bolder">{{ $t('filters.title') }}</div>
        </div>

        <div class="separator border-gray-200"></div>

        <div class="px-7 py-5 overflow-auto max-h-300px">
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('common.id') }}:</label>
                <div>
                    <el-input v-model="filterData.id" oninput="value=value.replace(/[^0-9.]/g,'')"/>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.module.teacherBookRequest.firstname') }}:</label>
                <div>
                    <el-input v-model="filterData.firstname" type="text"/>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.module.teacherBookRequest.lastname') }}:</label>
                <div>
                    <el-input v-model="filterData.lastname" type="text"/>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.module.teacherBookRequest.email') }}:</label>
                <div>
                    <el-input v-model="filterData.email" type="text"/>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.module.teacherBookRequest.phone') }}:</label>
                <div>
                    <el-input v-model="filterData.mobile_phone" type="text"/>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.module.teacherBookRequest.corporationName') }}:</label>
                <div>
                    <el-input v-model="filterData.corporation_name" type="text"/>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.module.teacherBookRequest.states') }}:</label>
                <div>
                    <el-select v-model="filterData.states" class="w-100" :placeholder="$t('common.chooseSelect')" clearable multiple filterable>
                        <el-option v-for="(state, stateIndex) in states" :key="stateIndex" :value="state.id" :label="state.name"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.module.teacherBookRequest.createdAtMin') }}:</label>
                <div>
                    <el-date-picker class="w-100" v-model="filterData['created_at[min]']" type="date" :placeholder="$t('common.chooseDate')" valueFormat="YYYY-MM-DD" :disabledDate="disabledMinDate" clearable></el-date-picker>
                </div>
            </div>
            <div class="">
                <label class="form-label fw-bold">{{ $t('filters.module.teacherBookRequest.createdAtMax') }}:</label>
                <div>
                    <el-date-picker class="w-100" v-model="filterData['created_at[max]']" type="date" :placeholder="$t('common.chooseDate')" valueFormat="YYYY-MM-DD" :disabledDate="disabledMaxDate" clearable></el-date-picker>
                </div>
            </div>
        </div>
        <div class="separator border-gray-200"></div>
        <div class="px-7 py-5">
            <div class="d-flex justify-content-end">
                <button type="reset" v-on:click="onReset" class="btn btn-sm btn-white btn-active-light-primary me-2" data-kt-menu-dismiss="true">{{ $t('btn.reset') }}</button>
                <button type="submit" v-on:click="onFilter" class="btn btn-sm btn-primary" data-kt-menu-dismiss="true">{{ $t('btn.apply') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "index",
    data(){
        return {
            filterData: {}
        }
    },
    computed: {
        states() {
            return this.$store.state.localization.country.state.table.data;
        },
    },
    mounted() {
        this.$store.dispatch('localization/country/state/get', {
            page: {pageSize: 9999},
            filterData: {
                country_id: 1
            }
        })
    },
    methods: {
        onReset(){
            this.filterData = {};
            this.onFilter();
        },
        onFilter(){
            this.$emit('onFilter', this.filterData);
        },
        disabledMinDate(startDate) {
            startDate = this.$moment(startDate);
            const endDate = this.filterData['created_at[max]'] ? this.$moment(this.filterData['created_at[max]']) : undefined;

            return endDate == undefined ? false : startDate.valueOf() > endDate.valueOf()
        },
        disabledMaxDate(endDate) {
            endDate = this.$moment(endDate);
            const startDate = this.filterData['created_at[min]'] ? this.$moment(this.filterData['created_at[min]']) : undefined;

            return startDate == undefined ? false : startDate.valueOf() > endDate.valueOf();
        },
    }
}
</script>

<style>

</style>