<template>
    <div class="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true">
        <div class="px-7 py-5">
            <div class="fs-5 text-dark fw-bolder">{{ $t('filters.title') }}</div>
        </div>

        <div class="separator border-gray-200"></div>

        <div class="px-7 py-5 overflow-auto max-h-300px">
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.ecommerce.order.id') }}:</label>
                <div>
                    <el-input v-model="filterData.id" oninput="value=value.replace(/[^0-9.]/g,'')"/>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.ecommerce.order.status') }}:</label>
                <div>
                    <el-select v-model="filterData.statuses" class="w-100" :placeholder="$t('common.chooseSelect')" clearable multiple>
                        <el-option v-for="(status, statusIndex) in orderStatus" :key="statusIndex" :value="status.id" :label="status.name">{{ status.name }}</el-option>
                    </el-select>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.ecommerce.order.paymentMethod') }}:</label>
                <div>
                    <el-select v-model="filterData.payment_method_ids" class="w-100" :placeholder="$t('common.chooseSelect')" clearable multiple>
                        <el-option v-for="(paymentMethod, paymentMethodIndex) in paymentMethods" :key="paymentMethodIndex" :value="paymentMethod.id" :label="paymentMethod.name">{{ paymentMethod.name }}</el-option>
                    </el-select>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.ecommerce.order.shippingMethod') }}:</label>
                <div>
                    <el-select v-model="filterData.shipping_method_id" class="w-100" :placeholder="$t('common.chooseSelect')" clearable>
                        <el-option v-for="(shippingMethod, shippingMethodIndex) in shippingMethods" :key="shippingMethodIndex" :value="shippingMethod.id" :label="shippingMethod.name">{{ shippingMethod.name }}</el-option>
                    </el-select>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.ecommerce.order.shippingTrackingNumber') }}:</label>
                <div>
                    <el-input v-model="filterData.shipping_tracking_number" type="text"/>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.ecommerce.order.notShipped') }}:</label>
                <div>
                    <el-select v-model="filterData.not_shipped" class="w-100" :placeholder="$t('common.chooseSelect')" clearable>
                        <el-option :value="1" :label="$t('common.yes')"></el-option>
                        <el-option :value="0" :label="$t('common.no')"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.ecommerce.order.installmentCount') }}:</label>
                <div>
                    <el-select v-model="filterData.installment_count" class="w-100" :placeholder="$t('common.chooseSelect')" clearable>
                        <el-option v-for="(installment, installmentIndex) in installments" :key="installmentIndex" :value="installment" :label="installment == 0 ? $t('common.cash') : installment"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.ecommerce.order.containingProduct') }}:</label>
                <div>
                    <el-select v-model="filterData.containing_products" class="w-100" :placeholder="$t('common.chooseSelect')" clearable filterable multiple>
                        <el-option v-for="(product, productIndex) in products" :key="productIndex" :value="product.id" :label="product.translate.title + ' (' + product.group.name + ')'"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.ecommerce.order.couponCode') }}:</label>
                <div>
                    <el-input v-model="filterData.coupon_code" type="text"/>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.ecommerce.order.admin') }}:</label>
                <div>
                    <el-select v-model="customModels.adminID" @change="changedAdminID" class="w-100" :placeholder="$t('common.chooseSelect')" clearable>
                        <el-option v-for="(admin, adminIndex) in admins" :key="adminIndex" :value="admin.id" :label="admin.firstname + ' ' + admin.lastname"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.ecommerce.order.onlyUserOrder') }}:</label>
                <div>
                    <el-radio-group v-model="customModels.onlyUserOrder" @change="changedOnlyUserOrder">
                        <el-radio-button :label="0">{{ $t("common.yes") }}</el-radio-button>
                        <el-radio-button :label="undefined">{{ $t("common.no") }}</el-radio-button>
                    </el-radio-group>
                </div>
            </div>
            <div class="mb-10" v-if="!userID">
                <label class="form-label fw-bold">{{ $t('filters.ecommerce.order.userEmail') }}:</label>
                <div>
                    <el-input v-model="filterData.user_email" type="text"/>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.ecommerce.order.states') }}:</label>
                <div>
                    <el-select v-model="filterData.states" class="w-100" :placeholder="$t('common.chooseSelect')" clearable multiple filterable>
                        <el-option v-for="(state, stateIndex) in states" :key="stateIndex" :value="state.id" :label="state.name"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.ecommerce.order.createdAtMin') }}:</label>
                <div>
                    <el-date-picker class="w-100" v-model="filterData['created_at[min]']" type="date" :placeholder="$t('common.chooseDate')" valueFormat="YYYY-MM-DD" :disabledDate="disabledMinDate" clearable></el-date-picker>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.ecommerce.order.createdAtMax') }}:</label>
                <div>
                    <el-date-picker class="w-100" v-model="filterData['created_at[max]']" type="date" :placeholder="$t('common.chooseDate')" valueFormat="YYYY-MM-DD" :disabledDate="disabledMaxDate" clearable></el-date-picker>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.ecommerce.order.usageFilter.title') }}:</label>
                <div>
                    <el-select v-model="filterData.usage_filters" class="w-100" :placeholder="$t('common.chooseSelect')" clearable multiple>
                        <el-option v-for="(item, itemIndex) in usageFilterOptions" :key="itemIndex" :value="(itemIndex + 1)" :label="$t('filters.ecommerce.order.usageFilter.option.' + item)"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.ecommerce.order.usageDataMin') }}:</label>
                <div>
                    <el-date-picker class="w-100" v-model="filterData['usage_date[min]']" type="date" :placeholder="$t('common.chooseDate')" valueFormat="YYYY-MM-DD" :disabledDate="disabledUsageMinDate" clearable></el-date-picker>
                </div>
            </div>
            <div class="">
                <label class="form-label fw-bold">{{ $t('filters.ecommerce.order.usageDataMax') }}:</label>
                <div>
                    <el-date-picker class="w-100" v-model="filterData['usage_date[max]']" type="date" :placeholder="$t('common.chooseDate')" valueFormat="YYYY-MM-DD" :disabledDate="disabledUsageMaxDate" clearable></el-date-picker>
                </div>
            </div>
        </div>
        <div class="separator border-gray-200"></div>
        <div class="px-7 py-5">
            <div class="d-flex justify-content-end">
                <button type="reset" v-on:click="onReset" class="btn btn-sm btn-white btn-active-light-primary me-2" data-kt-menu-dismiss="true">{{ $t('btn.reset') }}</button>
                <button type="submit" v-on:click="onFilter" class="btn btn-sm btn-primary" data-kt-menu-dismiss="true">{{ $t('btn.apply') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "index",
    data(){
        return {
            filterData: {},
            installments: [0, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            customModels: {
                adminID: undefined,
                onlyUserOrder: 0
            },
            usageFilterOptions: ['inUse', 'ended', 'notStarted']
        }
    },
    computed: {
        orderStatus() {
            return this.$store.state.ecommerce.order.status.table.data;
        },
        paymentMethods() {
            return this.$store.state.ecommerce.paymentMethod.table.data;
        },
        shippingMethods() {
            return this.$store.state.ecommerce.shippingMethod.table.data;
        },
        products() {
            let products = this.$store.state.ecommerce.productManagement.product.table.data.map((product) => {
                product.translate = this.resolveDatum(product.translations, this.$root.defaultLanguage.id, 'language_id')

                return product;
            })

            return products;
        },
        admins() {
            return this.$store.state.system.admin.table.data;
        },
        userID() {
            return this.$route.query.userID;
        },
        states() {
            return this.$store.state.localization.country.state.table.data;
        },
    },
    created(){
        this.customModels.onlyUserOrder = this.userID ? undefined : 0;
    },
    mounted(){
        this.$store.dispatch('ecommerce/order/status/get', {
            page: { pageSize: 9999 }
        });
        this.$store.dispatch('ecommerce/paymentMethod/get', {
            page: { pageSize: 9999 }
        });
        this.$store.dispatch('ecommerce/shippingMethod/get', {
            page: { pageSize: 9999 }
        });
        this.$store.dispatch('system/admin/get', {
            page: { pageSize: 9999 }
        });
        this.$store.dispatch('ecommerce/productManagement/product/get', {
            page: { pageSize: 9999 }
        });
        this.$store.dispatch('localization/country/state/get', {
            page: {pageSize: 9999},
            filterData: {
                country_id: 1
            }
        })
    },
    methods: {
        onReset(){
            this.filterData = {};
            this.onFilter();
        },
        onFilter(){
            this.$emit('onFilter', this.filterData);
        },
        disabledMinDate(startDate) {
            startDate = this.$moment(startDate);
            const endDate = this.filterData['created_at[max]'] ? this.$moment(this.filterData['created_at[max]']) : undefined;

            return endDate == undefined ? false : startDate.valueOf() > endDate.valueOf()
        },
        disabledMaxDate(endDate) {
            endDate = this.$moment(endDate);
            const startDate = this.filterData['created_at[min]'] ? this.$moment(this.filterData['created_at[min]']) : undefined;

            return startDate == undefined ? false : startDate.valueOf() > endDate.valueOf();
        },
        disabledUsageMinDate(startDate) {
            startDate = this.$moment(startDate);
            const endDate = this.filterData['usage_date[max]'] ? this.$moment(this.filterData['usage_date[max]']) : undefined;

            return endDate == undefined ? false : startDate.valueOf() > endDate.valueOf()
        },
        disabledUsageMaxDate(endDate) {
            endDate = this.$moment(endDate);
            const startDate = this.filterData['usage_date[min]'] ? this.$moment(this.filterData['usage_date[min]']) : undefined;

            return startDate == undefined ? false : startDate.valueOf() > endDate.valueOf();
        },
        changedAdminID(){
            this.filterData.admin_id = JSON.parse(JSON.stringify(this.customModels.adminID));
            this.customModels.onlyUserOrder = undefined;
        },
        changedOnlyUserOrder(){
            this.filterData.admin_id = JSON.parse(JSON.stringify(this.customModels.onlyUserOrder));
            this.customModels.adminID = undefined;
        }
    }
}
</script>

<style>

</style>