<template>
    <div class="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true">
        <div class="px-7 py-5">
            <div class="fs-5 text-dark fw-bolder">{{ $t('filters.title') }}</div>
        </div>

        <div class="separator border-gray-200"></div>

        <div class="px-7 py-5 overflow-auto max-h-300px">
            <div class="mb-10" v-if="!branchCourseID">
                <label class="form-label fw-bold">{{ $t('filters.module.branchCourse.reservation.branchCourse') }}:</label>
                <div>
                    <el-select v-model="filterData.branch_course_id" class="w-100" :placeholder="$t('common.chooseSelect')" clearable>
                        <el-option v-for="(branchCourse, branchCourseIndex) in branchCourses" :key="branchCourseIndex" :value="branchCourse.id" :label="branchCourse.title + ' (' + branchCourse.branch.title + ')'"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.module.branchCourse.reservation.user') }}:</label>
                <div>
                    <el-select
                        class="w-100"
                        v-model="filterData.user_id"
                        :filterable="user.filterable"
                        clearable
                        remote
                        :remote-method="remoteSearchUser"
                        :loading="user.loading"
                        :placeholder="$t('filters.module.branchCourse.reservation.userSearch')">
                        <el-option v-for="(user, userIndex) in user.list" :key="userIndex" :value="user.id" :label="user.full_name + ' (' + user.email +')'"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.module.branchCourse.reservation.firstname') }}:</label>
                <div>
                    <el-input v-model="filterData.firstname" type="text"/>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.module.branchCourse.reservation.lastname') }}:</label>
                <div>
                    <el-input v-model="filterData.lastname" type="text"/>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.module.branchCourse.reservation.email') }}:</label>
                <div>
                    <el-input v-model="filterData.email" type="text"/>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.module.branchCourse.reservation.mobilePhone') }}:</label>
                <div>
                    <el-input v-model="filterData.mobile_phone" type="text"/>
                </div>
            </div>
            <div class="">
                <label class="form-label fw-bold">{{ $t('filters.module.branchCourse.reservation.approved') }}:</label>
                <div>
                    <el-select v-model="filterData.approved" class="w-100" :placeholder="$t('common.chooseSelect')" clearable>
                        <el-option :value="true" :label="$t('common.yes')"></el-option>
                        <el-option :value="false" :label="$t('common.no')"></el-option>
                    </el-select>
                </div>
            </div>
        </div>

        <div class="separator border-gray-200"></div>

        <div class="px-7 py-5">
            <div class="d-flex justify-content-end">
                <button type="reset" v-on:click="onReset" class="btn btn-sm btn-white btn-active-light-primary me-2" data-kt-menu-dismiss="true">{{ $t('btn.reset') }}</button>
                <button type="submit" v-on:click="onFilter" class="btn btn-sm btn-primary" data-kt-menu-dismiss="true">{{ $t('btn.apply') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "index",
    data(){
        return {
            filterData: {},
            user: {
                filterable: true,
                loading: false,
                list: []
            },
        }
    },
    computed:{
        branchCourses() {
            return this.$store.state.module.branchCourse.table.data;
        },
        branchCourseID() {
            return this.$route.query.branchCourseID;
        }
    },
    mounted(){
        this.$store.dispatch('module/branchCourse/get', {
            page: { pageSize: 9999 }
        });
    },
    methods: {
        onReset(){
            this.filterData = {};
            this.onFilter();
        },
        onFilter(){
            this.$emit('onFilter', this.filterData);
        },
        remoteSearchUser(query) {
            if (query) {
                this.user.loading = true;
                this.axios.get(this.endpoints['user'], {
                    params: {
                        magic: query
                    }
                }).then(response => {
                    let data = response.data.data;
                    this.user.list = data;
                }).finally(() => {
                    this.user.loading = false;
                });
            } else {
                this.user.list = [];
            }
        },
    }
}
</script>

<style>

</style>