<template>
    <div class="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true">
        <div class="px-7 py-5">
            <div class="fs-5 text-dark fw-bolder">{{ $t('filters.title') }}</div>
        </div>

        <div class="separator border-gray-200"></div>

        <div class="px-7 py-5 overflow-auto max-h-300px">
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.module.exam.session.user.userID') }}:</label>
                <div>
                    <el-input v-model="filterData.user_id" type="text"/>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.module.exam.session.user.examID') }}:</label>
                <div>
                    <el-input v-model="filterData.exam_id" type="text"/>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.module.exam.session.user.sessionID') }}:</label>
                <div>
                    <el-input v-model="filterData.session_id" type="text"/>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.module.exam.session.user.userEmail') }}:</label>
                <div>
                    <el-input v-model="filterData.user_email" type="text"/>
                </div>
            </div>
            <div class="mb-7 text-gray-700 fs-6">
                <label class="form-check form-check-custom form-check-solid">
                    <input v-model="filterData.just_reservation" class="form-check-input" type="checkbox" value="1" :checked="filterData.just_reservation">
                    <span class="form-check-label">{{ $t('filters.module.exam.session.user.justReservation') }}</span>
                </label>
            </div>
            <div class="mb-7 text-gray-700 fs-6">
                <label class="form-check form-check-custom form-check-solid">
                    <input v-model="filterData.just_started" class="form-check-input" type="checkbox" value="1" :checked="filterData.just_started">
                    <span class="form-check-label">{{ $t('filters.module.exam.session.user.justStarted') }}</span>
                </label>
            </div>
            <div class="text-gray-700 fs-6">
                <label class="form-check form-check-custom form-check-solid">
                    <input v-model="filterData.just_ended" class="form-check-input" type="checkbox" value="1" :checked="filterData.just_ended">
                    <span class="form-check-label">{{ $t('filters.module.exam.session.user.justEnded') }}</span>
                </label>
            </div>
        </div>

        <div class="separator border-gray-200"></div>

        <div class="px-7 py-5">
            <div class="d-flex justify-content-end">
                <button type="reset" v-on:click="onReset" class="btn btn-sm btn-white btn-active-light-primary me-2" data-kt-menu-dismiss="true">{{ $t('btn.reset') }}</button>
                <button type="submit" v-on:click="onFilter" class="btn btn-sm btn-primary" data-kt-menu-dismiss="true">{{ $t('btn.apply') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import ElSelectTree from "@/components/ElSelectTree.vue";

export default {
    name: "index",
    components: {
        ElSelectTree
    },
    data(){
        return {
            filterData: {}
        }
    },
    computed: {
        sessionID(){
            return this.$route.query.sessionID;
        },
        examID(){
            return this.$route.query.examID;
        }
    },
    mounted(){
        if(this.sessionID){
            this.filterData.session_id = this.sessionID;
        }
        if(this.examID){
            this.filterData.exam_id = this.examID;
        }
    },
    methods: {
        onReset(){
            this.filterData = {};
            this.onFilter();
        },
        onFilter(){
            let filterData = this.cloneData(this.filterData);
            filterData.just_reservation = this.filterData.just_reservation ? 1 : undefined;
            filterData.just_started = this.filterData.just_started ? 1 : undefined;
            filterData.just_ended = this.filterData.just_ended ? 1 : undefined;

            this.$emit('onFilter', filterData);
        }
    }
}
</script>

<style>

</style>