<template>
    <div class="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true">
        <div class="px-7 py-5">
            <div class="fs-5 text-dark fw-bolder">{{ $t('filters.title') }}</div>
        </div>

        <div class="separator border-gray-200"></div>

        <div class="px-7 py-5 overflow-auto max-h-300px">
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('common.id') }}:</label>
                <div>
                    <el-input v-model="filterData.id" oninput="value=value.replace(/[^0-9.]/g,'')"/>
                </div>
            </div>

            <div class="mb-10" v-for="(element, elementIndex) in formElements.filter(element => (elementTypes.filter((type) => ['input'].includes(type.code)).map(item => item.id).includes(element.type_id)))" :key="elementIndex">
                <label class="form-label fw-bold">{{ element.translate.title }}:</label>
                <div>
                    <el-input v-model="filterData['elements[' + element.id + ']']" />
                </div>
            </div>

            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.module.form.answer.ip') }}:</label>
                <div>
                    <el-input v-model="filterData.ip"/>
                </div>
            </div>

            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.module.form.answer.createdAtMin') }}:</label>
                <div>
                    <el-date-picker class="w-100" v-model="filterData['created_at[min]']" type="date" :placeholder="$t('common.chooseDate')" valueFormat="YYYY-MM-DD" :disabledDate="disabledMinDate" clearable></el-date-picker>
                </div>
            </div>

            <div class="">
                <label class="form-label fw-bold">{{ $t('filters.module.form.answer.createdAtMax') }}:</label>
                <div>
                    <el-date-picker class="w-100" v-model="filterData['created_at[max]']" type="date" :placeholder="$t('common.chooseDate')" valueFormat="YYYY-MM-DD" :disabledDate="disabledMaxDate" clearable></el-date-picker>
                </div>
            </div>
        </div>

        <div class="separator border-gray-200"></div>

        <div class="px-7 py-5">
            <div class="d-flex justify-content-end">
                <button type="reset" v-on:click="onReset" class="btn btn-sm btn-white btn-active-light-primary me-2" data-kt-menu-dismiss="true">{{ $t('btn.reset') }}</button>
                <button type="submit" v-on:click="onFilter" class="btn btn-sm btn-primary" data-kt-menu-dismiss="true">{{ $t('btn.apply') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "index",
    data() {
        return {
            formId: null,
            formElements: [],
            filterData: {}
        }
    },
    computed: {
        elementTypes() {
            return this.$store.state.module.form.element.type.table.data;
        }
    },
    created() {
        this.eventBus.$on('setCurrentFormId', (formId) => {
            this.formId = formId;
            this.loadFormElements();
        });
    },
    mounted() {
        this.eventBus.$emit('getCurrentFormId');
        this.$store.dispatch('module/form/element/type/get');
    },
    beforeUnmount() {
        this.eventBus.$off('setCurrentFormId', undefined, true);
    },
    methods: {
        loadFormElements() {
            this.axios.get(this.endpoints['module_form'] + '/' + this.formId).then((response) => {
                this.formElements = response.data.data.elements.map((element) => {
                    element.type = this.elementTypes.find((elementType) => elementType.id === element.type_id);
                    element.translate = this.resolveDatum(element.translations, this.$root.defaultLanguage.id, 'language_id');
                    return element;
                });
            });
        },
        onReset() {
            this.filterData = {};
            this.onFilter();
        },
        onFilter() {
            this.$emit('onFilter', this.filterData);
        },
        disabledMinDate(startDate) {
            startDate = this.$moment(startDate);
            const endDate = this.filterData['created_at[max]'] ? this.$moment(this.filterData['created_at[max]']) : undefined;

            return endDate == undefined ? false : startDate.valueOf() > endDate.valueOf()
        },
        disabledMaxDate(endDate) {
            endDate = this.$moment(endDate);
            const startDate = this.filterData['created_at[min]'] ? this.$moment(this.filterData['created_at[min]']) : undefined;

            return startDate == undefined ? false : startDate.valueOf() > endDate.valueOf();
        }
    }
}
</script>

<style>

</style>