<template>
    <div class="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true">
        <div class="px-7 py-5">
            <div class="fs-5 text-dark fw-bolder">{{ $t('filters.title') }}</div>
        </div>

        <div class="separator border-gray-200"></div>

        <div class="px-7 py-5 overflow-auto max-h-300px">
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.ecommerce.discountCoupon.code') }}:</label>
                <div>
                    <el-input v-model="filterData.code" type="text"/>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.ecommerce.discountCoupon.discountType') }}:</label>
                <div>
                    <el-select v-model="filterData.discount_type" class="w-100" :placeholder="$t('common.chooseSelect')" clearable>
                        <el-option :value="1" :label="$t('pages.ecommerce.discountCoupon.couponTypes.static')"></el-option>
                        <el-option :value="2" :label="$t('pages.ecommerce.discountCoupon.couponTypes.percent')"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.ecommerce.discountCoupon.startDate') }}:</label>
                <div>
                    <el-date-picker class="w-100 rangeDateTimePicker" popper-class="rangeDateTimePickerPopper"  v-model="filterData.start_date" type="datetime" :placeholder="$t('common.chooseDate')" valueFormat="YYYY-MM-DD HH:mm:ss" :disabledDate="disabledMinDate" clearable></el-date-picker>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.ecommerce.discountCoupon.endDate') }}:</label>
                <div>
                    <el-date-picker class="w-100 rangeDateTimePicker" popper-class="rangeDateTimePickerPopper" v-model="filterData.end_date" type="datetime" :placeholder="$t('common.chooseDate')" valueFormat="YYYY-MM-DD HH:mm:ss" :disabledDate="disabledMaxDate" clearable></el-date-picker>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.ecommerce.discountCoupon.createdAtMin') }}:</label>
                <div>
                    <el-date-picker class="w-100" v-model="filterData['created_at[min]']" type="date" :placeholder="$t('common.chooseDate')" valueFormat="YYYY-MM-DD" :disabledDate="disabledCreatedMinDate" clearable></el-date-picker>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.ecommerce.discountCoupon.createdAtMax') }}:</label>
                <div>
                    <el-date-picker class="w-100" v-model="filterData['created_at[max]']" type="date" :placeholder="$t('common.chooseDate')" valueFormat="YYYY-MM-DD" :disabledDate="disabledCreatedMaxDate" clearable></el-date-picker>
                </div>
            </div>
            <div class="">
                <label class="form-label fw-bold">{{ $t('filters.ecommerce.discountCoupon.used') }}:</label>
                <div>
                    <el-select v-model="filterData.used" class="w-100" :placeholder="$t('common.chooseSelect')" clearable>
                        <el-option :value="true" :label="$t('common.yes')"></el-option>
                        <el-option :value="false" :label="$t('common.no')"></el-option>
                    </el-select>
                </div>
            </div>
        </div>
        <div class="separator border-gray-200"></div>
        <div class="px-7 py-5">
            <div class="d-flex justify-content-end">
                <button type="reset" v-on:click="onReset" class="btn btn-sm btn-white btn-active-light-primary me-2" data-kt-menu-dismiss="true">{{ $t('btn.reset') }}</button>
                <button type="submit" v-on:click="onFilter" class="btn btn-sm btn-primary" data-kt-menu-dismiss="true">{{ $t('btn.apply') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "index",
    data(){
        return {
            filterData: {}
        }
    },
    methods: {
        onReset(){
            this.filterData = {};
            this.onFilter();
        },
        onFilter(){
            this.$emit('onFilter', this.filterData);
        },
        disabledMinDate(startDate) {
            startDate = this.$moment(startDate);
            const endDate = this.filterData.end_date ? this.$moment(this.filterData.end_date) : undefined;

            return endDate == undefined ? false : startDate.valueOf() > endDate.valueOf()
        },
        disabledMaxDate(endDate) {
            endDate = this.$moment(endDate);
            const startDate = this.filterData.start_date ? this.$moment(this.filterData.start_date) : undefined;

            return startDate == undefined ? false : startDate.valueOf() > endDate.valueOf();
        },
        disabledCreatedMinDate(startDate) {
            startDate = this.$moment(startDate);
            const endDate = this.filterData['created_at[max]'] ? this.$moment(this.filterData['created_at[max]']) : undefined;

            return endDate == undefined ? false : startDate.valueOf() > endDate.valueOf()
        },
        disabledCreatedMaxDate(endDate) {
            endDate = this.$moment(endDate);
            const startDate = this.filterData['created_at[min]'] ? this.$moment(this.filterData['created_at[min]']) : undefined;

            return startDate == undefined ? false : startDate.valueOf() > endDate.valueOf();
        },
    }
}
</script>

<style>

</style>