<template>
    <div class="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true">
        <div class="px-7 py-5">
            <div class="fs-5 text-dark fw-bolder">{{ $t('filters.title') }}</div>
        </div>

        <div class="separator border-gray-200"></div>

        <div class="px-7 py-5 overflow-auto max-h-300px">
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('common.id') }}:</label>
                <div>
                    <el-input v-model="filterData.id" oninput="value=value.replace(/[^0-9.]/g,'')"/>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.system.linkManager.type') }}:</label>
                <div>
                    <el-select v-model="filterData.linkable_type" class="w-100" :placeholder="$t('common.chooseSelect')" clearable>
                        <el-option v-for="(type, typeIndex) in types" :key="typeIndex" :value="type.code" :label="type.name"></el-option>
                        <el-option :key="301" :value="301" :label="301"></el-option>
                        <el-option :key="302" :value="302" :label="302"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="">
                <label class="form-label fw-bold">{{ $t('filters.system.linkManager.slug') }}:</label>
                <div>
                    <el-input v-model="filterData.slug" type="text"/>
                </div>
            </div>
        </div>
        <div class="separator border-gray-200"></div>
        <div class="px-7 py-5">
            <div class="d-flex justify-content-end">
                <button type="reset" v-on:click="onReset" class="btn btn-sm btn-white btn-active-light-primary me-2" data-kt-menu-dismiss="true">{{ $t('btn.reset') }}</button>
                <button type="submit" v-on:click="onFilter" class="btn btn-sm btn-primary" data-kt-menu-dismiss="true">{{ $t('btn.apply') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "index",
    data(){
        return {
            filterData: {}
        }
    },
    computed: {
        types(){
            return this.$store.state.linkManager.linkType.table.data;
        }
    },
    mounted(){
        this.$store.dispatch('linkManager/linkType/get');
    },
    methods: {
        onReset(){
            this.filterData = {};
            this.onFilter();
        },
        onFilter(){
            this.$emit('onFilter', this.filterData);
        }
    }
}
</script>

<style>

</style>