<template>
    <div class="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true">
        <div class="px-7 py-5">
            <div class="fs-5 text-dark fw-bolder">{{ $t('filters.title') }}</div>
        </div>

        <div class="separator border-gray-200"></div>

        <div class="px-7 py-5 overflow-auto max-h-300px">
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.module.exam.question.category') }}:</label>
                <div>
                    <el-input type="hidden" v-model="filterData.category_id"></el-input>
                    <el-select-tree :value="filterData.category_id" @input="filterData.category_id = $event" :data="treeCategories" :placeholder="$t('common.chooseSelect')" :multiple="false"/>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.module.exam.question.type') }}:</label>
                <div>
                    <el-select v-model="filterData.type_id" class="w-100" :placeholder="$t('common.chooseSelect')" clearable>
                        <el-option v-for="(type, typeIndex) in types" :key="typeIndex" :value="type.id" :label="type.name"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.module.exam.question.gain') }}:</label>
                <div>
                    <el-select v-model="gainModel" class="w-100" :placeholder="$t('common.chooseSelect')" clearable multiple @change="changedGain">
                        <el-option v-for="(gain, gainIndex) in gains" :key="gainIndex" :value="gain.id" :label="gain.translate.title"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="">
                <label class="form-label fw-bold">{{ $t('filters.module.exam.question.magic') }}:</label>
                <div>
                    <el-input v-model="filterData.magic" type="text"/>
                </div>
            </div>
        </div>

        <div class="separator border-gray-200"></div>

        <div class="px-7 py-5">
            <div class="d-flex justify-content-end">
                <button type="reset" v-on:click="onReset" class="btn btn-sm btn-white btn-active-light-primary me-2" data-kt-menu-dismiss="true">{{ $t('btn.reset') }}</button>
                <button type="submit" v-on:click="onFilter" class="btn btn-sm btn-primary" data-kt-menu-dismiss="true">{{ $t('btn.apply') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import ElSelectTree from "@/components/ElSelectTree.vue";

export default {
    name: "index",
    components: {
        ElSelectTree
    },
    data(){
        return {
            filterData: {},
            gainModel: [],
            treeCategories: [],
        }
    },
    computed: {
        types() {
            return this.$store.state.module.exam.question.type.table.data;
        },
        gains() {
            return this.$store.state.module.exam.gain.table.data.map((gain) => {
                gain.translate =  this.resolveDatum(gain.translations, this.$root.defaultLanguage.id, 'language_id');

                return gain;
            });
        },
    },
    mounted(){
        this.loadTreeCategories();

        this.$store.dispatch('module/exam/gain/get', {
            page: { pageSize: 9999 }
        });
    },
    methods: {
        onReset(){
            this.filterData = {};
            this.gainModel = [];
            this.onFilter();
        },
        onFilter(){
            this.$emit('onFilter', this.filterData);
        },
        loadTreeCategories() {
            this.axios.get(this.endpoints['module_exam_question_category'] + '/tree').then((response) => {
                this.treeCategories = response.data.data;
            });
        },
        changedGain(){
            let gains = undefined;

            if(this.gainModel.length){
                gains = this.gainModel.join(',');
            }

            this.filterData.gains = gains;
        }
    }
}
</script>

<style>

</style>