<template>
    <div class="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true">
        <div class="px-7 py-5">
            <div class="fs-5 text-dark fw-bolder">{{ $t('filters.title') }}</div>
        </div>

        <div class="separator border-gray-200"></div>

        <div class="px-7 py-5 overflow-auto max-h-300px">
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.module.exam.category') }}:</label>
                <div>
                    <el-input type="hidden" v-model="filterData.category_id"></el-input>
                    <el-select-tree :value="filterData.category_id" @input="filterData.category_id = $event" :data="treeCategories" :placeholder="$t('common.chooseSelect')" :multiple="false"/>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.module.exam.templateType') }}:</label>
                <div>
                    <el-select v-model="filterData.template_type_id" class="w-100" :placeholder="$t('common.chooseSelect')" clearable>
                        <el-option v-for="(templateType, templateTypeIndex) in templateTypes" :key="templateTypeIndex" :value="templateType.id" :label="templateType.name"></el-option>
                    </el-select>
                </div>
            </div>

            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.module.exam.questionType') }}:</label>
                <div>
                    <el-select v-model="filterData.question_type_id" class="w-100" :placeholder="$t('common.chooseSelect')">
                        <el-option v-for="(questionType, questionTypeIndex) in questionTypes" :key="questionTypeIndex" :value="questionType.id" :label="questionType.name"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.module.exam.examDate') }}:</label>
                <div>
                    <el-date-picker v-model="filterData.exam_date" type="date" :placeholder="$t('common.chooseDate')" valueFormat="YYYY-MM-DD"></el-date-picker>
                </div>
            </div>
            <div class="mb-10">
                <label class="form-label fw-bold">{{ $t('filters.module.exam.examResulted') }}:</label>
                <div>
                    <el-select v-model="filterData.exam_resulted" class="w-100" :placeholder="$t('common.chooseSelect')" clearable>
                        <el-option :value="true" :label="$t('common.yes')"></el-option>
                        <el-option :value="false" :label="$t('common.no')"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="">
                <label class="form-label fw-bold">{{ $t('filters.module.exam.withoutSession') }}:</label>
                <div>
                    <el-select v-model="filterData.without_session" class="w-100" :placeholder="$t('common.chooseSelect')" clearable>
                        <el-option :value="true" :label="$t('common.yes')"></el-option>
                        <el-option :value="false" :label="$t('common.no')"></el-option>
                    </el-select>
                </div>
            </div>
        </div>

        <div class="separator border-gray-200"></div>

        <div class="px-7 py-5">
            <div class="d-flex justify-content-end">
                <button type="reset" v-on:click="onReset" class="btn btn-sm btn-white btn-active-light-primary me-2" data-kt-menu-dismiss="true">{{ $t('btn.reset') }}</button>
                <button type="submit" v-on:click="onFilter" class="btn btn-sm btn-primary" data-kt-menu-dismiss="true">{{ $t('btn.apply') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import ElSelectTree from "@/components/ElSelectTree.vue";

export default {
    name: "index",
    components: {
        ElSelectTree
    },
    data(){
        return {
            filterData: {},
            treeCategories: [],
        }
    },
    computed: {
        sessionID(){
            return this.$route.query.sessionID;
        },
        examID(){
            return this.$route.query.examID;
        },
        templateTypes() {
            return this.$store.state.module.exam.template.type.table.data;
        },
        questionTypes() {
            return this.$store.state.module.exam.question.type.table.data;
        },
    },
    mounted(){
        this.loadTreeCategories();

        this.$store.dispatch('module/exam/template/type/get', {
            page: { pageSize: 9999 }
        });

        this.$store.dispatch('module/exam/question/type/get', {
            page: { pageSize: 9999 }
        });
    },
    methods: {
        onReset(){
            this.filterData = {};
            this.onFilter();
        },
        onFilter(){
            this.$emit('onFilter', this.filterData);
        },

        loadTreeCategories() {
            this.axios.get(this.endpoints['module_exam_category'] + '/tree').then((response) => {
                this.treeCategories = response.data.data;
            });
        },
    }
}
</script>

<style>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 100%;
}
</style>