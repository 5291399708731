<template>
    <div class="card">
        <!--begin::Header-->
        <div class="card-header pt-4 pb-4" v-if="!hideHeader" :class="[cardConfig.removePadding && 'pe-0 ps-0']">
            <h3 class="card-title align-items-start flex-column">
                <template v-if="showTitle">
                    <span class="card-label fw-bolder fs-3 mb-1" v-if="title">{{ title }}</span>
                    <span class="text-muted mt-1 fw-bold fs-7" v-if="subTitle">{{ subTitle }}</span>
                </template>
            </h3>
            <div v-if="showRightButtons" class="card-toolbar">
                <el-dropdown v-if="selectedRowEvents.length && selectRows.checkedRows.length" class="me-2" @command="handleSelectedRowEvent">
                    <el-tooltip effect="dark" :content="$t('btn.selectedRowEvent')" placement="top">
                        <el-button size="small">
                            <i class="bi bi-ui-checks"></i>
                        </el-button>
                    </el-tooltip>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item :command="event.event" v-for="(event, eventIndex) in selectedRowEvents" :key="eventIndex"  :disabled="!(typeof(event.validDataRule) == 'undefined' || event.validDataRule(event.validDataRuleParams))">{{ event.label }}</el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
                <el-button-group>
                    <el-tooltip class="item" effect="dark" v-if="filterComponentName" :content="$t('btn.filter')" placement="top">
                        <el-button size="small" :class="!(actions && actions.length) && 'custom-rounded'" data-kt-menu-trigger="click" data-kt-menu-overflow="true" data-kt-menu-placement="bottom-end" data-kt-menu-flip="top-end">
                            <i class="bi bi-funnel"></i>
                        </el-button>
                    </el-tooltip>
                    <component v-if="filterComponentName" id="filterBox" v-bind:is="filterComponentName" @onFilter="filter" ref="filterComponent"></component>
                    <el-tooltip class="item" effect="dark" v-for="btn in actions" :key="btn.name" :content="$t(btn.label)" placement="top">
                        <el-button size="small" @click="handleClickAction(btn.name)">
                            <i class="bi" v-bind:class="[btn.icon]"></i>
                        </el-button>
                    </el-tooltip>
                </el-button-group>
            </div>
        </div>
        <!--end::Header-->

        <div class="card-body pt-0" :class="[cardConfig.removePadding && 'pe-0 ps-0']">
            <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                <div class="table-responsive">
                    <table :class="[loading && 'overlay overlay-block']" class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" id="kt_customers_table" role="grid">
                        <!--begin::Table head-->
                        <thead>
                        <!--begin::Table row-->
                        <tr class="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0" role="row">
                            <th class="text-center">
                                <div v-if="selectableRows" class="form-check form-check-sm form-check-custom form-check-solid">
                                    <input class="form-check-input" type="checkbox" v-model="selectRows.allCheck" @change="changeRowSelect($event, 'all')"/>
                                </div>
                                <div v-if="expandableRows"></div>
                            </th>
                            <template v-for="(cell, i) in columns" :key="i">
                                <th @click="sort(cell.sortingField ? cell.sortingField : cell.key, cell.sortable)"
                                    :class="[
                                        {'sorting': (cell.sortingField ? cell.sortingField : cell.key) && cell.sortable, 'text-end': (columns.length - 1 === i) },
                                        {'sorting_desc': (currentSort === `${cell.sortingField ? cell.sortingField : cell.key}desc`) },
                                        {'sorting_asc': (currentSort === `${cell.sortingField ? cell.sortingField : cell.key}asc`) },
                                        cell.class ?? (cell.name ? 'min-w-125px' : '')
                                    ]" v-bind:style="cell.style">
                                    {{ cell.name }}
                                </th>
                            </template>
                        </tr>
                        <!--end::Table row-->
                        </thead>
                        <!--end::Table head-->
                        <!--begin::Table body-->
                        <tbody class="fw-bold text-gray-600">
                        <template v-if="getItems.length">
                            <TreeRow v-for="(item, itemIndex) in getItems" :key="itemIndex" :row="item" :rowIndex="itemIndex" v-bind="$props" :showRow="true">
                                <!-- pass through normal slots -->
                                <template v-for="(_, name) in $slots" v-slot:[name]="slotData">
                                    <slot :name="name" v-bind="slotData"/>
                                </template>
                            </TreeRow>
                        </template>
                        <template v-else>
                            <tr class="odd">
                                <td :colspan="(columns.length + 1)" class="dataTables_empty">
                                    {{ emptyTableText ?? $t('customTable.noData') }}
                                </td>
                            </tr>
                        </template>
                        </tbody>
                        <div v-if="loading" class="overlay-layer card-rounded bg-dark bg-opacity-5">
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">{{ $t('messages.loading') }}...</span>
                            </div>
                        </div>
                        <!--end::Table body-->
                    </table>
                </div>

                <div class="row" v-if="pagination">
                    <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-start">
                        <div class="d-block d-md-flex align-items-center">
                            <div v-if="enableItemsPerPageDropdown" class="dataTables_length text-center text-sm-left" id="kt_customers_table_length">
                                <label>
                                    <select name="kt_customers_table_length" class="form-select form-select-sm form-select-solid" @change="setItemsPerPage">
                                        <option v-for="(item, itemIndex) in perPageItems" :key="itemIndex" :value="item">{{ item }}</option>
                                    </select>
                                </label>
                            </div>
                            <div v-if="showingPageInformation && paginationObj.total > paginationObj.pageSize" class="fs-6 fw-bold text-gray-700 pb-3 pb-md-0 text-center mt-3 mt-md-0" :class="enableItemsPerPageDropdown && 'ms-0 ms-md-4'">{{ showingPageInformationText }}</div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-end">
                        <el-pagination
                            v-model:current-page="paginationObj.current"
                            @current-change="currentPageChange"
                            :page-size="paginationObj.pageSize"
                            layout="prev, pager, next"
                            :pager-count="3"
                            :total="paginationObj.total"
                            :hide-on-single-page="true"
                            background>
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TreeRow from '@/components/custom-table/treeRow.vue';
import arraySort from "array-sort";
import {getCurrentInstance} from "vue";
import {MenuComponent} from "@/assets/js/components/MenuComponent";

/* Filter Components */
import EcommerceProductFilter from "@/components/filter/ecommerce/product";
import EcommerceOrderFilter from "@/components/filter/ecommerce/order";
import EcommerceOrderChangeUsageMultiFilter from "@/components/filter/ecommerce/order/change-usage-multi";
import EcommerceOrderShippingFilter from "@/components/filter/ecommerce/order/shipping";
import EcommerceUserFilter from "@/components/filter/ecommerce/user";
import EcommerceDiscountCouponFilter from "@/components/filter/ecommerce/discount-coupon";
import EcommerceInvoiceFilter from "@/components/filter/ecommerce/invoice"
import EcommerceInvoiceProviderProductFilter from "@/components/filter/ecommerce/invoice/provider/product"
import ModuleBranchCourseReservationFilter from "@/components/filter/module/branch-course/reservation"
import ModuleBranchCourseBlockFilter from "@/components/filter/module/branch-course/block"
import ModuleExamFilter from "@/components/filter/module/exam";
import ModuleExamSessionUserFilter from "@/components/filter/module/exam/session/user"
import ModuleExamQuestionFilter from "@/components/filter/module/exam/question"
import ModuleExamResultFilter from "@/components/filter/module/exam/result"
import ModuleTrackingFilter from "@/components/filter/module/tracking"
import ModuleTeacherBookRequestFilter from "@/components/filter/module/teacher-book-request"
import ModuleYksLgsHazirlikDestegiFilter from "@/components/filter/module/yks-lgs-hazirlik-destegi"
import ModuleQuestionSolutionBookFilter from "@/components/filter/module/question-solution/book";
import ModuleQuestionSolutionBookChapterQuestionFilter from "@/components/filter/module/question-solution/book/chapter/question";
import ModuleFormAnswerFilter from "@/components/filter/module/form/answer";
import PageFilter from "@/components/filter/page"
import StatisticProductFilter from "@/components/filter/statistic/product"
import StatisticLessonFilter from "@/components/filter/statistic/lesson"
import SystemSmsProviderFilter from "@/components/filter/system/sms/provider";
import SystemLinkManagerFilter from "@/components/filter/system/link-manager";
/*import ModuleOutQuestionFilter from "@/components/filter/module/out-question";*/
/* Filter Components */

export default {
    name: "CustomTable",
    emit: ["current-change", "sort", "items-per-page-change"],
    props: {
        showTitle: {type: Boolean, default: true},
        hideHeader: {type: Boolean, default: false},
        showRightButtons: {type: Boolean, default: true},
        title: {type: String, default: undefined},
        subTitle: {type: String, default: undefined},
        columns: {type: Array, required: true},
        items: {type: Array, required: true},
        emptyTableText: {type: String, default: undefined},
        loading: {type: Boolean, default: false},
        currentPage: {type: Number, default: 1},
        enableItemsPerPageDropdown: {type: Boolean, default: false},
        order: {type: String, default: "asc"},
        runModeServer: {type: Boolean, default: true},
        sortLabel: {type: String, default: ""},
        actions: {type: Array},
        selectableRows: {type: Boolean, default: false},
        selectedRowEvents: {type: Array, default: () => { return []}},
        expandableRows: {type: Boolean, default: false},
        expandableRowConfig: {type: Object, default: () => { return {}}},
        pagination: {type: Object},
        rowKey: {type: String, default: undefined},
        filterComponentName: {type: String},
        showingPageInformation: {type: Boolean, default: true},
        cardConfig: {type: Object, default: () => { return {}}},
        changeUrlPage: {type: Boolean, default: false},
    },
    components: {
        'EcommerceProductFilter': EcommerceProductFilter,
        'EcommerceOrderFilter': EcommerceOrderFilter,
        'EcommerceOrderChangeUsageMultiFilter': EcommerceOrderChangeUsageMultiFilter,
        'EcommerceOrderShippingFilter': EcommerceOrderShippingFilter,
        'EcommerceUserFilter': EcommerceUserFilter,
        'EcommerceDiscountCouponFilter': EcommerceDiscountCouponFilter,
        'EcommerceInvoiceFilter': EcommerceInvoiceFilter,
        'EcommerceInvoiceProviderProductFilter': EcommerceInvoiceProviderProductFilter,
        'ModuleBranchCourseReservationFilter': ModuleBranchCourseReservationFilter,
        'ModuleBranchCourseBlockFilter': ModuleBranchCourseBlockFilter,
        'ModuleExamFilter': ModuleExamFilter,
        'ModuleExamSessionUserFilter': ModuleExamSessionUserFilter,
        'ModuleExamQuestionFilter': ModuleExamQuestionFilter,
        'ModuleExamResultFilter': ModuleExamResultFilter,
        'ModuleTrackingFilter': ModuleTrackingFilter,
        'ModuleTeacherBookRequestFilter': ModuleTeacherBookRequestFilter,
        'ModuleYksLgsHazirlikDestegiFilter': ModuleYksLgsHazirlikDestegiFilter,
        'ModuleQuestionSolutionBookFilter': ModuleQuestionSolutionBookFilter,
        'ModuleQuestionSolutionBookChapterQuestionFilter': ModuleQuestionSolutionBookChapterQuestionFilter,
        'ModuleFormAnswerFilter': ModuleFormAnswerFilter,
        'PageFilter': PageFilter,
        'StatisticProductFilter': StatisticProductFilter,
        'StatisticLessonFilter': StatisticLessonFilter,
        'SystemSmsProviderFilter': SystemSmsProviderFilter,
        'SystemLinkManagerFilter': SystemLinkManagerFilter,
        /*'ModuleOutQuestionFilter': ModuleOutQuestionFilter,*/
        TreeRow
    },
    data() {
        return {
            data: [],
            vnodeProps: {},
            currentSort: "",
            paginationObj: {},
            sortType: "asc",
            selectRows: {
                checkedRows: [],
                allCheck: false
            },
            filterData: undefined,
            localFilteredItems: undefined,
            perPageItems: [10, 25, 50, 100]
        }
    },
    provide() {
        return {
            changeRowSelect: this.changeRowSelect,
            changedExpandRow: this.changedExpandRow,
            selectRows: this.selectRows
        }
    },
    created() {
        this.initializeConfig();
        this.sortType = this.order;
        this.vnodeProps = getCurrentInstance()?.vnode.props || {};
    },
    computed: {
        getItems() {
            if (this.runModeServer) {
                return (this.paginationObj.pageSize > 100) ? [] : this.data;
            } else {
                let clone = this.cloneData(this.localFilteredItems ?? this.data);
                const startFrom = this.paginationObj.current * this.paginationObj.pageSize - this.paginationObj.pageSize;
                return clone.splice(startFrom, this.paginationObj.pageSize);
            }
        },
        showingPageInformationText() {
            let text = '';

            if (Object.keys(this.paginationObj).length) {
                let rangeStart = (this.paginationObj.current - 1) * this.paginationObj.pageSize;
                let rangeEnd = this.paginationObj.current * this.paginationObj.pageSize;

                text = this.sprintf(this.$t('customTable.showingPageInformationPattern'), [
                    this.paginationObj.total,
                    (rangeStart === 0 ? 1 : rangeStart),
                    (rangeEnd < this.paginationObj.total ? rangeEnd : this.paginationObj.total)
                ]);
            }

            return text;
        },
    },
    mounted() {
        this.currentSort = this.sortLabel + this.sortType;

        if (this.filterComponentName) {
            setTimeout(() => {
                MenuComponent.reinitialization();
            }, 500);

            if(this.$root.filterWithUrl && this.$refs.filterComponent){
                let urlParams = this.urlParams();

                if(Object.keys(urlParams).length) {
                    this.$refs.filterComponent.filterData = urlParams;
                    this.filterData = urlParams;
                }
            }
        }
    },
    methods: {
        initializeConfig() {
            this.loadData();
            this.loadPagination();
        },
        loadData() {
            this.data = this.$props.items;
            this.$nextTick(() => {
                this.updateSelectedRows();
            });
        },
        loadPagination() {
            if (this.pagination) {
                this.paginationObj = this.cloneData(this.pagination);
            }
        },
        handleClickAction(name) {
            this.$emit("action", name);
        },
        currentPageChange(val) {
            if(this.changeUrlPage){
                let query = {page: val};

                if(this.$root.filterWithUrl && this.filterData) {
                    query = Object.assign(this.filterData, query);
                }

                this.$router.push({path: this.$route.path, query: query});
            }

            if (this.runModeServer) {
                this.$emit("changeTable", this.paginationObj, this.filterData);
            } else {
                this.paginationObj.current = val;
            }

            this.selectRows.allCheck = false;
            this.changeRowSelect(null, 'all');
        },
        sort(columnName, sortable) {
            if (!columnName || !sortable) {
                return;
            }

            if ("onSort" in this.vnodeProps) {
                if (this.sortType === "asc") {
                    this.sortType = "desc";
                    this.$emit("sort", {columnName: columnName, order: "desc"});
                } else {
                    this.sortType = "asc";
                    this.$emit("sort", {columnName: columnName, order: "asc"});
                }
            } else {
                if (this.sortType === "asc") {
                    this.sortType = "desc";
                    arraySort(this.data, columnName, {reverse: true});
                } else {
                    this.sortType = "asc";
                    arraySort(this.data, columnName, {reverse: false});
                }
            }
            this.currentSort = columnName + this.sortType;
        },
        setItemsPerPage(event) {
            this.paginationObj = {
                current: 1,
                pageSize: parseInt(event.target.value),
                total: this.pagination.total
            };
            this.currentPageChange(1);
        },
        updateSelectedRows(retryHandleRows = true) {
            if (retryHandleRows) {
                let checkboxes = document.querySelectorAll(".selectableRows");
                let checkedRows = [];

                checkboxes.forEach((item) => {
                    if (item.checked) {
                        checkedRows.push(item._value);
                    }
                });

                this.selectRows.checkedRows = checkedRows;
            }

            this.selectRows.allCheck = this.selectRows.checkedRows.length > 0 && this.getNestedItems().length == this.selectRows.checkedRows.length;
        },
        changeRowSelect(event, type) {
            if (type == 'all') {
                if (this.selectRows.allCheck) {
                    this.selectRows.checkedRows = this.getNestedItems();
                } else {
                    this.selectRows.checkedRows = [];
                }
            }
            if (type == 'single') {
                this.updateSelectedRows(false);
            }
            this.$emit('selectableRows', this.selectRows.checkedRows);
        },
        getNestedItems(items = [], childItems = this.getItems) {
            for (let i = 0; i < childItems.length; i++) {
                let item = childItems[i];
                items.push(this.rowKey ? item[this.rowKey] : item);
                if (item.children) {
                    this.getNestedItems(items, item.children);
                }
            }
            return items;
        },
        changedExpandRow(record, expandStatus) {
            this.$emit('expandableRow', record, expandStatus);
        },
        filter(filterData) {
            this.filterData = this.cloneData(filterData);

            if (this.runModeServer) {
                if(this.$root.filterWithUrl) {
                    this.$router.push(this.$route.path + this.objectToUrlQuery(filterData));
                }

                this.$emit("changeTable", this.paginationObj, filterData)
            } else {
                this.applyLocalFilter();
            }
        },
        handleSelectedRowEvent(command) {
            command(this.selectRows.checkedRows);
        },
        applyLocalFilter(){
            let clone = this.cloneData(this.data);
            if(this.filterData && Object.values(this.filterData).length > 0) {
                clone = clone.filter((item) => {
                    for(let filterKey in this.filterData){
                        let filterValue = this.filterData[filterKey];
                        if(filterValue && filterValue.trim().length && item[filterKey] && !item[filterKey].toLocaleLowerCase('tr-TR').includes(filterValue.toLocaleLowerCase('tr-TR'))){
                            return false;
                        }
                    }
                    return true;
                });
            }

            this.localFilteredItems = clone;
            this.paginationObj.total = clone.length;

            this.currentPageChange(1);
        }
    },
    watch: {
        items: function () {
            this.loadData();
        },
        pagination: function () {
            this.loadPagination();
        },
        data: function () {
            if (this.runModeServer) {
                this.currentSort = this.sortLabel + this.sortType;
            } else {
                this.paginationObj.total = this.data.length;
            }
        },
        '$route.query.page': {
            handler(newValue, oldValue) {
                if(this.changeUrlPage && newValue != oldValue && newValue > 0 && newValue != this.paginationObj.current){
                    this.paginationObj.current = newValue;
                    this.currentPageChange(newValue);
                }
            },
            deep: true
        }
    }
}
</script>

<style lang="scss">

table.dataTable {
    clear: both;
    margin-top: 6px !important;
    margin-bottom: 6px !important;
    max-width: none !important;
    border-collapse: separate !important;
    border-spacing: 0;
}

table.dataTable > thead {
    th:first-of-type {
        padding-right: 0.75rem !important;
        padding-left: 0.75rem !important;
    }

    th:first-of-type:empty {
        display: none;
    }

    th:last-child {
        padding-right: 0.75rem !important;
    }

    th.sorting {
        position: relative;
        cursor: pointer;
    }

    .sorting:after {
        position: absolute;
    }
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
    background: none;
    border-radius: 0.475rem;
    font-weight: 500;
    font-size: 1.075rem;
    font-family: Poppins, Helvetica, sans-serif;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #009ef7;
}

.el-pager li.btn-quicknext, .el-pager li.btn-quickprev {
    line-height: 25px !important;
}

table.dataTable td.dataTables_empty,
table.dataTable th.dataTables_empty {
    text-align: center;
}

div.dataTables_wrapper div.dataTables_processing {
    position: absolute;
    top: 50%;
    left: 50%;
}

table.dataTable > tbody {
    tr {
        td:first-of-type {
            padding-left: 0.75rem !important;
        }
    }

    tr:not(.expandRow) {
        td:first-of-type:empty {
            display: none;
        }

        td:last-child {
            padding-right: 0.75rem !important;
        }
    }

    .expandColumn > div {
        margin-right: 0 !important;
    }
}

.custom-rounded {
    border-radius: calc(4px - 1px) !important;
}
</style>
